
export const utils = {
  isHtmlScroll: () => {
    const html = $('html'), top = html.scrollTop();
    const el = $('<div/>').height(10000).prependTo('body');
    html.scrollTop(10000);
    const rs = !!html.scrollTop();
    html.scrollTop(top);
    el.remove();
    return rs;
  }
};