import { utils } from './module/_util';
import { uaObj } from './module/_ua_check';
import SmoothScroll from './module/_smoothscroll';


(() => {
  document.addEventListener('DOMContentLoaded', () => {
    const _window = $(window);
    const _globalNavi = $('#globalNavi');
    const _btnPagetop = $('#btnPagetop');
    const _ovBG = $('#ovBG');
    const _ovBody = $('#ovBody');
    const _ovClose = $('#ovClose');

    let windowH = _window.height();
    let scrollTop = _window.scrollTop();

    $('.target').scrollClass();
    SmoothScroll();

    $('#btnMenu a').on('click', function () {
      $(this).toggleClass('active');
      _globalNavi.toggleClass('active');
    });


    // modal
    const modal = {
      open: function (id) {
        _ovBG.addClass('active');
        _ovBody.addClass('active');
        _ovClose.addClass('active');
        $('.ov_content').removeClass('active');
        $('#' + id).addClass('active');
      },

      close: function () {
        _ovBG.removeClass('active');
        _ovBody.removeClass('active');
        _ovClose.removeClass('active');
      }
    };


    $('.modal').on('click', function () {
      const id = $(this).data('modal');
      modal.open(id);
    });

    _ovClose.on('click', function () {
      modal.close();
    });

    $(window).on('resize', function () {
      windowH = _window.height();
    });

    $(window).on('scroll', function () {
      scrollTop = _window.scrollTop();
      const hitLine = windowH + scrollTop;
      const bottomPos = $('#globalFooter').offset().top;
      const bottomHitLine = $('#wrapper').height() - 300;

      if (hitLine >= bottomHitLine + 116) {
        _btnPagetop.css({ 'position': 'absolute', 'bottom': 'about', 'top': bottomHitLine - 84 });
      }
      else {
        _btnPagetop.css({ 'position': 'fixed', 'bottom': 75, 'top': 'auto' });
      }

      if (scrollTop > 500) {
        _btnPagetop.addClass('active');
      }
      else {
        _btnPagetop.removeClass('active');
      }
    });



    if ($('#slider').length) {
      let currentNum = 0;
      let displayNum = 0;
      const _parent = $('#slider');
      const _child = _parent.find('li');
      const childLength = _child.length;
      const _control = $('.control');

      _control.addClass('start');

      setInterval(() => {
        _control.removeClass('start');
        currentNum++;
        if (currentNum > childLength - 1) currentNum = 0;

        _child.removeClass('active').eq(currentNum).addClass('active');

        displayNum = 10 < currentNum ? currentNum + 1 : '0' + (currentNum + 1);
        _control.find('.before').html(displayNum);
        setTimeout(() => {
          _control.addClass('start');
        }, 200);
      }, 6000);
    }
  }, false);
})();



